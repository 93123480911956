const emailParams = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

export const rulesValidationEmail = {
  validator: (_, value) => {
    if (value) {
      return (!emailParams.test(value)
        ? Promise.reject(new Error('Некорректный email-адрес'))
        : Promise.resolve());
    }

    return Promise.resolve();
  },
};

const conventQuilToText = (quil) => quil.replace(/<(.|\n)*?>/g, '');

export const requiredQuill = {
  validator: (_, value) => ((conventQuilToText(value || '').length === 0)
    ? Promise.reject(new Error('Заполните все обязательные поля'))
    : Promise.resolve()),
};

export const validateFullName = {
  validator: (_, fullName) => {
    const trimmedFullName = (fullName || '').trim();

    if (trimmedFullName.length < 4) {
      return Promise.reject(new Error('ФИО должно содержать минимум 4 буквы'));
    }

    return Promise.resolve();
  },
};
